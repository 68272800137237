$mm_module: ".mm-panel";

/**
 * The searchpanel
 */
#{$mm_module}--search {
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    border: none !important;
}

/**
 * Splash message
 */
#{$mm_module}__splash {
    padding: v.$panelPadding;

    #{$mm_module}--searching & {
        display: none;
    }
}

/**
 * No results message
 */
#{$mm_module}__noresults {
    display: none;
    padding: v.$panelPadding * 2 v.$panelPadding;
    color: var(--mm-color-text-dimmed);
    text-align: center;
    font-size: 150%;
    line-height: 1.4;

    #{$mm_module}--noresults & {
        display: block;
    }
}
