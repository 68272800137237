:host([fx="collapse"]) {
    //  Hamburger
    .bar {
        &--top {
            transition-property: bottom, margin, transform;
            transition-delay: 
                calc(var(--mb-animation-delay) + var(--mb-animation-duration)),
                var(--mb-animation-delay), 
                var(--mb-animation-delay);
        }

        &--middle {
            transition-property: top, opacity;
            transition-duration:
                var(--mb-animation-duration),
                0s;
            transition-delay: 
                calc(var(--mb-animation-delay) + (var(--mb-animation-duration) * 1.3)),
                calc(var(--mb-animation-delay) + (var(--mb-animation-duration) * 1.3));
        }

        &--bottom {
            transition-delay: var(--mb-animation-delay);
        }
    }

    //  Cross
    .is-cross .bar {
        &--top {
            bottom: calc(50% - var(--mb-bar-spacing) - var(--mb-bar-height));
            margin-bottom: calc(var(--mb-bar-spacing) + (var(--mb-bar-height) / 2));
            transform: rotate(45deg);
            transition-delay: 
                calc(var(--mb-animation-delay) + (var(--mb-animation-duration) * 0.3)),
                calc(var(--mb-animation-delay) + (var(--mb-animation-duration) * 1.3)),
                calc(var(--mb-animation-delay) + (var(--mb-animation-duration) * 1.3));
        }

        &--middle {
            top: calc(50% + var(--mb-bar-spacing));
            opacity: 0;
            transition-duration:
                var(--mb-animation-duration),
                0s;
            transition-delay: 
                var(--mb-animation-delay),
                calc(var(--mb-animation-delay) + var(--mb-animation-duration));
        }

        &--bottom {
            top: calc(50% - (var(--mb-bar-height) / 2));
            transform: rotate(-45deg);
            transition-delay: 
                calc(var(--mb-animation-delay) + (var(--mb-animation-duration) * 1.3)),
                calc(var(--mb-animation-delay) + (var(--mb-animation-duration) * 1.3));
        }
    }
}
