$themeColor: rgb(0,0,0);

@import "/node_modules/swiper/swiper.scss";
@import '/node_modules/swiper/swiper-bundle.css';




.swiper-button-prev,
.swiper-button-next{
  background-color:$theme-primary;
  color:$white;
  width: 32px;
  height: 44px;
  transform: translateY(calc(-50% - 5px));
  border-radius:0.35rem;
  @include ease;
  &.swiper-button-disabled{
    opacity:0.25;
  }        
  @include media-breakpoint-down(xl) {
    display:none;
  }
}  
.swiper-button-prev{
  left:0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.swiper-button-next{
  right:0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
} 
.swiper-button-prev:after, 
.swiper-button-next:after {
  font-size: 1.25rem;
}


.swiper-scrollbar {
  background: rgba(0, 0, 0, .15);
  border-radius: 0;
}
.swiper-horizontal > .swiper-scrollbar, 
.swiper-scrollbar.swiper-scrollbar-horizontal {
  height: 6px;
}
.swiper-scrollbar-drag {
  background: rgb(0,0,0);
  border-radius: 0;
}




/* galleryswiper */
.galleryswiper{
  overflow:hidden;
  padding-bottom:32px;
  position:relative;
  .swiper-slide{   
      aspect-ratio:4/3;
      background-color:$theme-grey-light;    
      padding:1rem;
      cursor:pointer;
    img{
      object-fit: contain;
      object-position:50% 50%;
      width:100%;
      height:100%;  
    }
  }
  .scrollbar-container{
    display:flex;
    align-items: center;
    gap:20px;    
    margin-top: 1rem;
    overflow: hidden;
    position: relative;
    width:100%;  
    .swiper-scrollbar{
      position:relative;
      width:100%;
      top:0;
      right:0;
      bottom:0;
      left:0;
      cursor:pointer;
    }
    /* .swiper-button-prev,
    .swiper-button-next{
      position:relative;
      top:0;
      right:0;
      bottom:0;
      left:0;
      margin:0;
      @include ease;
      &.swiper-button-disabled{
        &:after{
          opacity:0.3;
        }
      }        
      @include media-breakpoint-down(xl) {
        display:none;
      }
    }   
    .swiper-button-prev:after, 
    .swiper-button-next:after {
      font-size: 1.5rem;
    } */
  }
}





/* objectswiper */
.objectswiper{
	overflow:hidden;
  padding-bottom:32px;
	position:relative;
  .swiper-slide{
    aspect-ratio:4/3;
    background-color:$theme-grey-light;
    padding:1rem;    
    cursor:pointer;
    img{
      object-fit: contain;
      object-position:50% 50%;
      width:100%;
      height:100%;
    }
  }
  .scrollbar-container{
    display:flex;
    align-items: center;
    gap:20px;    
    margin-top: 1rem;
    overflow: hidden;
    position: relative;
    width:100%;  
    .swiper-scrollbar{
      position:relative;
      width:100%;
      top:0;
      right:0;
      bottom:0;
      left:0;
      cursor:pointer;
    }
    /* .swiper-button-prev,
    .swiper-button-next{
      position:relative;
      top:0;
      right:0;
      bottom:0;
      left:0;
      margin:0;
      @include ease;
      &.swiper-button-disabled{
        &:after{
          opacity:0.3;
        }
      }        
      @include media-breakpoint-down(xl) {
        display:none;
      }
    }   
    .swiper-button-prev:after, 
    .swiper-button-next:after {
      font-size: 1.5rem;
    } */
  }
}

.fullscreen-swiper {
	display: none;
	overflow: hidden;
	position: fixed;
	top: 50%;
	left: 15px;
	right: 15px;
	z-index: 10000;
	transform: translateY(-50%);
  text-align:center;
  .swiper-slide{ 
    display:flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  .scrollbar-container{
    display:flex;
    align-items: center;
    gap:20px;    
    margin:1rem auto 0 auto;
    overflow: hidden;
    position: relative;
    width:100%;  
    max-width:1000px;
    .swiper-scrollbar{
      position:relative;
      width:100%;
      top:0;
      right:0;
      bottom:0;
      left:0;
      cursor:pointer;
    }
    /* .swiper-button-prev,
    .swiper-button-next{
      position:relative;
      top:0;
      right:0;
      bottom:0;
      left:0;
      margin:0;
      @include ease;
      &.swiper-button-disabled{
        &:after{
          opacity:0.3;
        }
      }        
      @include media-breakpoint-down(xl) {
        display:none;
      }
    }   
    .swiper-button-prev:after, 
    .swiper-button-next:after {
      font-size: 1.5rem;
    } */
  }
  .swiper-slide{
    img{
      max-height: 75vh;
      width:auto;
      max-width: 100%;
    }
  }
  .swiper-button-prev,
  .swiper-button-next{
    width: 44px;
    height: 52px;
    border-radius:0.35rem;
    transform: translateY(calc(-50% + 12px));
  }   
  .swiper-button-prev:after, 
  .swiper-button-next:after {
    font-size: 1.5rem;
  }
}

.fullscreen-swiper-backdrop {
  background: $theme-grey-light;
  opacity:1;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;	
}
.fullscreen-swiper-close {
  background-color:$black;
  color:$white;
  width: 36px;
  height: 36px;
  border-radius:0.35rem;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 10000;
  display:flex;
  align-items: center;
  justify-content: center;
}
  
  /* .no-scroll {
    height: 100%; 
    overflow: hidden;
  } */

