






.dataTables_wrapper{
    padding:0 15px;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: none;
  padding: 10px;
}
table.dataTable tbody th,
table.dataTable tbody td {
  padding: 10px;
}
table.dataTable.no-footer {
    border-bottom: 1px solid $theme-tableborder;
}
table.dataTable.table-hover > tbody > tr:hover > * {
  box-shadow: none;
}
.table > :not(caption) > * > * {
    box-shadow: none;
    border-bottom-width: 0;
}
table.dataTable.table-striped > tbody > tr.odd > * {
  box-shadow: none;
}