// Height
$s2bs5-height:                   $input-height !default;
$s2bs5-height-inner:             $input-line-height * 1rem !default;

// Z-Index
$s2bs5-zindex:                   $zindex-modal + 1 !default;

// Padding
$s2bs5-padding-y:                $form-select-padding-y !default;
$s2bs5-padding-x:                $form-select-padding-x !default;

// Font
$s2bs5-font-family:              inherit !default;
$s2bs5-font-size:                $form-select-font-size !default;
$s2bs5-font-weight:              $form-select-font-weight !default;
$s2bs5-line-height:              $form-select-line-height !default;

// Color & background
$s2bs5-color:                    $form-select-color !default;
$s2bs5-placeholder-color:        $input-placeholder-color !default;
$s2bs5-bg:                       $form-select-bg !default;
$s2bs5-bg-position:              $form-select-bg-position !default;
$s2bs5-bg-size:                  $form-select-bg-size !default;

// Border & shadow
$s2bs5-border-width:             $form-select-border-width !default;
$s2bs5-border-color:             $form-select-border-color !default;
$s2bs5-border-radius:            $form-select-border-radius !default;
$s2bs5-box-shadow:               $form-select-box-shadow !default;
$s2bs5-transition:               $input-transition !default;

// Indicator
$s2bs5-indicator-padding:        $form-select-indicator-padding !default;
$s2bs5-indicator:                $form-select-indicator !default;

// Focus
$s2bs5-focus-border-color:       $form-select-focus-border-color !default;
$s2bs5-focus-width:              $form-select-focus-width !default;
$s2bs5-focus-box-shadow:         $form-select-focus-box-shadow !default;

// Valid
$s2bs5-valid-border-color:       $form-feedback-valid-color !default;
$s2bs5-valid-focus-box-shadow:   0 0 0 $s2bs5-focus-width rgba($s2bs5-valid-border-color, $input-btn-focus-color-opacity) !default;

// Invalid
$s2bs5-invalid-border-color:     $form-feedback-invalid-color !default;
$s2bs5-invalid-focus-box-shadow: 0 0 0 $s2bs5-focus-width rgba($s2bs5-invalid-border-color, $input-btn-focus-color-opacity) !default;

// Disabled
$s2bs5-disabled-color:           $text-muted !default;
$s2bs5-disabled-bg:              $form-select-disabled-bg !default;
$s2bs5-disabled-border-color:    $s2bs5-border-color !default;

// Items
$s2bs5-item-padding-y:           $s2bs5-padding-y !default;
$s2bs5-item-padding-x:           $s2bs5-padding-x !default;
$s2bs5-item-active-bg:           $primary !default;
$s2bs5-item-hover-bg:            $gray-200 !default;
$s2bs5-item-focus-bg:            $gray-200 !default;

// Groups
$s2bs5-group-padding-y:          $s2bs5-padding-y !default;
$s2bs5-group-padding-x:          $s2bs5-padding-x * .5 !default;
$s2bs5-group-color:              $secondary !default;
$s2bs5-group-font-weight:        $headings-font-weight !default;

// Multiple items
$s2bs5-multi-item-padding-y:     $badge-padding-y !default;
$s2bs5-multi-item-padding-x:     $badge-padding-x !default;

$s2bs5-options-max-height:       15rem !default;

// Clear/Remove
$s2bs5-clear-width:              .75rem !default;
$s2bs5-clear-height:             .75rem !default;
$s2bs5-clear-padding-y:          $btn-close-padding-y !default;
$s2bs5-clear-padding-x:          $btn-close-padding-x !default;
$s2bs5-clear-icon:               str-replace($btn-close-bg, #{$btn-close-color}, #{shade-color($s2bs5-border-color, 50%)}) !default;
$s2bs5-clear-bg:                 transparent escape-svg($s2bs5-clear-icon) center / $s2bs5-clear-height auto no-repeat !default;
$s2bs5-clear-hover-bg:           transparent escape-svg($btn-close-bg) center / $s2bs5-clear-height auto no-repeat !default;

// Small
$s2bs5-height-sm:                $input-height-sm !default;
$s2bs5-height-inner-sm:          $input-line-height * 1em !default;
$s2bs5-padding-y-sm:             $form-select-padding-y-sm !default;
$s2bs5-padding-x-sm:             $form-select-padding-x-sm !default;
$s2bs5-font-size-sm:             $form-select-font-size-sm !default;
$s2bs5-border-radius-sm:         $form-select-border-radius-sm !default;
$s2bs5-item-padding-y-sm:        $s2bs5-padding-y-sm !default;
$s2bs5-item-padding-x-sm:        $s2bs5-padding-x-sm !default;
$s2bs5-group-padding-y-sm:       $s2bs5-padding-y-sm !default;
$s2bs5-group-padding-x-sm:       $s2bs5-padding-x-sm * .5 !default;
$s2bs5-clear-width-sm:           .5rem !default;
$s2bs5-clear-height-sm:          .5rem !default;
$s2bs5-clear-padding-y-sm:       .125rem !default;
$s2bs5-clear-padding-x-sm:       .125rem !default;
$s2bs5-clear-bg-sm:              transparent escape-svg($s2bs5-clear-icon) center / $s2bs5-clear-height-sm auto no-repeat !default;
$s2bs5-clear-hover-bg-sm:        transparent escape-svg($btn-close-bg) center / $s2bs5-clear-height-sm auto no-repeat !default;

$s2bs5-small: (
    "height": $s2bs5-height-sm,
    "height-inner": $s2bs5-height-inner-sm,
    "padding-y": $s2bs5-padding-y-sm,
    "padding-x": $s2bs5-padding-x-sm,
    "font-size": $s2bs5-font-size-sm,
    "border-radius": $s2bs5-border-radius-sm,
    "item-padding-y": $s2bs5-item-padding-y-sm,
    "item-padding-x": $s2bs5-item-padding-x-sm,
    "group-padding-y": $s2bs5-group-padding-y-sm,
    "group-padding-x": $s2bs5-group-padding-x-sm,
    "clear-width": $s2bs5-clear-width-sm,
    "clear-height": $s2bs5-clear-height-sm,
    "clear-padding-y": $s2bs5-clear-padding-y-sm,
    "clear-padding-x": $s2bs5-clear-padding-x-sm,
    "clear-bg": $s2bs5-clear-bg-sm,
    "clear-hover-bg": $s2bs5-clear-hover-bg-sm,
) !default;

// Large
$s2bs5-height-lg:                $input-height-lg !default;
$s2bs5-height-inner-lg:          $input-line-height * 1em !default;
$s2bs5-padding-y-lg:             $form-select-padding-y-lg !default;
$s2bs5-padding-x-lg:             $form-select-padding-x-lg !default;
$s2bs5-font-size-lg:             $form-select-font-size-lg !default;
$s2bs5-border-radius-lg:         $form-select-border-radius-lg !default;
$s2bs5-item-padding-y-lg:        $s2bs5-padding-y-lg !default;
$s2bs5-item-padding-x-lg:        $s2bs5-padding-x-lg !default;
$s2bs5-group-padding-y-lg:       $s2bs5-padding-y-lg !default;
$s2bs5-group-padding-x-lg:       $s2bs5-padding-x-lg * .5 !default;
$s2bs5-clear-width-lg:           1rem !default;
$s2bs5-clear-height-lg:          1rem !default;
$s2bs5-clear-padding-y-lg:       .5rem !default;
$s2bs5-clear-padding-x-lg:       .5rem !default;
$s2bs5-clear-bg-lg:              transparent escape-svg($s2bs5-clear-icon) center / $s2bs5-clear-height-lg auto no-repeat !default;
$s2bs5-clear-hover-bg-lg:        transparent escape-svg($btn-close-bg) center / $s2bs5-clear-height-lg auto no-repeat !default;

$s2bs5-large: (
    "height": $s2bs5-height-lg,
    "height-inner": $s2bs5-height-inner-lg,
    "padding-y": $s2bs5-padding-y-lg,
    "padding-x": $s2bs5-padding-x-lg,
    "font-size": $s2bs5-font-size-lg,
    "border-radius": $s2bs5-border-radius-lg,
    "item-padding-y": $s2bs5-item-padding-y-lg,
    "item-padding-x": $s2bs5-item-padding-x-lg,
    "group-padding-y": $s2bs5-group-padding-y-lg,
    "group-padding-x": $s2bs5-group-padding-x-lg,
    "clear-width": $s2bs5-clear-width-lg,
    "clear-height": $s2bs5-clear-height-lg,
    "clear-padding-y": $s2bs5-clear-padding-y-lg,
    "clear-padding-x": $s2bs5-clear-padding-x-lg,
    "clear-bg": $s2bs5-clear-bg-lg,
    "clear-hover-bg": $s2bs5-clear-hover-bg-lg,
) !default;
