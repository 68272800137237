:host,
* {
    box-sizing: border-box;
}

:host {
    /** Width for the button. */
    --mb-bar-width: 30px;

    /** Height for the bars.*/
    --mb-bar-height: 3px;

    /** Distance between bars. */
    --mb-bar-spacing: 7px;

    /** Basic animation timing function. */
    --mb-animation-function: ease;

    /** Animation duration. */
    --mb-animation-duration: 0.25s;

    /** Timeout before starting the animation, for example to starts after the menu is fully opened. */
    --mb-animation-delay: 0s;

    display: inline-flex;
    align-items: center;
    gap: 0.75em;
    padding: 0;
    margin: 0;
    font-size: inherit;
    color: inherit;
    border: unset;
    background: none;
    cursor: pointer;
}

:host([ease="elastic"]) {
    --mb-animation-function: cubic-bezier(.5,-0.35,.35,1.5);
    --mb-animation-duration: 0.35s;
}

:host([ease="funky"]) {
    --mb-animation-function: cubic-bezier(0,1.45,.5,1.45);
    --mb-animation-duration: 0.4s;
}

:host([ease="shaky"]) {
    --mb-animation-function: cubic-bezier(.15,.33,1,-0.81);
    --mb-animation-function: cubic-bezier(0,.5,1,-1);
    --mb-animation-duration: 0.45s;
}

//	Hamburger wrapper
[part="bars"] {
    position: relative;
    display: block;
    width: var(--mb-bar-width);
    height: calc((var(--mb-bar-height) * 3) + (var(--mb-bar-spacing) * 2));
}

//	Hamburger
.bar {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: var(--mb-bar-height);
    border-radius: calc(var(--mb-bar-height) / 2);
    background: currentColor;
    color: inherit;
    opacity: 1;

    transition: none var(--mb-animation-duration) var(--mb-animation-function) var(--mb-animation-delay);
    
    //	Bar 1
    &--top {
        bottom: calc(50% + var(--mb-bar-spacing) + (var(--mb-bar-height) / 2));
        transition-property: bottom, transform;
    }

    //	Bar 2
    &--middle {
        top: calc(50% - (var(--mb-bar-height) / 2));
        transition-property: opacity;
    }

    //	Bar 3
    &--bottom {
        top: calc(50% + var(--mb-bar-spacing) + (var(--mb-bar-height) / 2));
        transition-property: top, transform;
    }
}

//  Cross
.is-cross {
    .bar {
        &--top {
            bottom: calc(50% - (var(--mb-bar-height) / 2));
            transform: rotate(45deg);
        }

        &--middle {
            opacity: 0;
        }

        &--bottom {
            top: calc(50% - (var(--mb-bar-height) / 2));
            transform: rotate(-45deg);
        }
    }
}

@import "fx.collapse";
@import "fx.spin";
@import "fx.squeeze";
@import "fx.tornado";
