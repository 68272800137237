@use "../../variables" as v;

$mm_module: ".mm-searchfield";

/**
 * The form.
 */
#{$mm_module} {
    display: flex;
    flex-grow: 1;
    height: var(--mm-navbar-size);
    padding: 0;
    overflow: hidden;
}
/**
 * The fieldset
 */
#{$mm_module}__input {
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 0 10px;
    box-sizing: border-box;

    // Input
    input {
        display: block;
        width: 100%;
        max-width: 100%;
        height: calc(var(--mm-navbar-size) * 0.7);
        min-height: auto;
        max-height: auto;
        margin: 0;
        padding: 0 10px;
        box-sizing: border-box;
        border: none;
        border-radius: 4px;
        line-height: calc(var(--mm-navbar-size) * 0.7);
        font: inherit;
        font-size: inherit;

        &,
        &:hover,
        &:focus {
            background: var(--mm-color-background-highlight);
            color: var(--mm-color-text);
        }

        .mm-menu[class*="-contrast"] & {
            border: 1px solid var(--mm-color-border);
        }
    }

    input::-ms-clear {
        display: none;
    }
}

/**
 * Submit and reset buttons.
 */
#{$mm_module}__btn {
    display: none;
    position: absolute;
    inset-inline-end: 0; // right, left for RTL
    top: 0;
    bottom: 0;

    #{$mm_module}--searching & {
        display: block;
    }
}

/**
 * Cancel button.
 */
#{$mm_module}__cancel {
    display: block;
    position: relative;
    margin-inline-end: -100px; // right, left for RTL
    padding-inline-start: 5px; // left, right for RTL
    padding-inline-end: v.$listitemIndent; // right, left for RTL
    visibility: hidden;
    line-height: var(--mm-navbar-size);
    text-decoration: none;
    transition-property: visibility, margin;

    #{$mm_module}--cancelable & {
        visibility: visible;
        margin-inline-end: 0; // right, left for RTL
    }
}

@import "./panel";
