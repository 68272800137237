#{$module}-light {
    --mm-color-background: #f3f3f3;
    --mm-color-border: rgb(0 0 0 / 0.15);
    --mm-color-icon: rgb(0 0 0 / 0.4);
    --mm-color-text: rgb(0 0 0 / 0.8);
    --mm-color-text-dimmed: rgb(0 0 0 / 0.4);
    --mm-color-background-highlight: rgb(0 0 0 / 0.05);
    --mm-color-background-emphasis: rgb(255 255 255 / 0.75);
    --mm-color-focusring: #06c;

    &-contrast {
        --mm-color-background: #f3f3f3;
        --mm-color-border: rgb(0 0 0 / 0.5);
        --mm-color-icon: rgb(0 0 0 / 0.5);
        --mm-color-text: #000;
        --mm-color-text-dimmed: rgb(0 0 0 / 0.7);
        --mm-color-background-highlight: rgb(0 0 0 / 0.05);
        --mm-color-background-emphasis: rgb(255 255 255 / 0.9);
        --mm-color-focusring: #06c;
    }
}
