// Global Styles

/* basic */

html{  
	font-size:16px;
}

body{
	background-color:$theme-grey-light;
}


b,
strong {
	font-family:$theme-font-bold;
	font-weight:normal;
}


::-moz-selection {
	background: $theme-primary;
	color: $white;
	text-shadow: none;
}

::selection {
	background: $theme-primary;
	color: $white;
	text-shadow: none;
}

img {
	width: 100%;
	height: auto;
}

.container {
	position: relative;
}


address, dl, ol, ul {
	margin-bottom: 1.5rem;
}


.status-label{	
	color:$white;
	padding:0.5rem;		
	text-align:center;
	border-radius:0.35rem;		
	font-size: 13px;
	font-family: $theme-font-bold;
	text-transform: uppercase;
	line-height: 1.2;
	background-color:$theme-grey-medium;
}

.bg-neutral{
	background-color:$theme-grey-dark;
}

dt{
	font-weight:normal;
	font-family: $theme-font-bold;
}


/* .form-control::-webkit-input-placeholder {
	color: $black; opacity: 0.5;
}
.form-control::-moz-placeholder {
	color: $black; opacity: 0.5;
}
.form-control:-ms-input-placeholder { 
	color: $black; opacity: 0.5;
}
.form-control:-moz-placeholder { 
	color: $black; opacity: 0.5;
}
.form-control:placeholder { 
	color: $black; opacity: 0.5;
} */





.form-control{
	font-size:16px;
}


input[type="password"] {
    font-family: Verdana;
    letter-spacing: 0.125em;
}

.form-floating > label {
    font-size:16px;
}

.form-control[readonly] {
    opacity: 1;
    background-color: #e9ecef;
}


.btn-primary,
.btn-secondary,
.btn-success,
.btn-danger,
.btn-warning,
.btn-info,
.btn-dark{
	color:$white;
	&:hover{
		color:$white;
	}
}

.btn{
	font-family:$theme-font-bold;
	font-weight:normal;
	&:hover{
		color:$white;
	}
}
.btn-sm{
	font-family:$theme-font-light;
	font-weight:normal;
}

.btn-check:checked + .btn, 
:not(.btn-check) + .btn:active, 
.btn:first-child:active, .btn.active, 
.btn.show {
    color: $white;
}

.btn-group{
	&.btn-group-und-oder{
		label{
			font-size: 13px;
			padding:0.5rem 1.5rem;			
			text-transform: uppercase;
			line-height: 1.2;
			min-width: 85px;
			font-family:$theme-font-regular;
			letter-spacing: 0.1em;
		}
	}
}



.btn [class^="icon-"],
a [class^="icon-"] {
	margin-right:0.25em;
	font-size:1.5em;
	vertical-align: middle;
}

.btn-sm [class^="icon-"]{
	font-size:1.25em;
	margin-right:0.5em;
}


.toast-body{
	color:$white;
}

/* bg-colors */
.bg-danger,
.text-bg-danger{
	color: $white!important;
	background-color:$theme-danger!important;
}
.bg-warning,
.text-bg-warning{
	color: $white!important;
	background-color:$theme-warning!important;
}
.bg-success,
.text-bg-success{
	color: $white!important;
	background-color:$theme-success!important;
}
.bg-neutral,
.text-bg-neutral{
	color: $white!important;
	background-color:$secondary!important;
}

/* badge */
.badge{
	font-weight:normal;
	font-family:$theme-font-medium;
}

/* nav-tabs */
.nav-tabs{
	background-color:$theme-grey-light;
	border-radius: 0.35rem 0.35rem 0 0;
	font-family:$theme-font-medium;
}
.nav-tabs .nav-link {
    border-top: 3px solid transparent;
	color:$theme-grey-dark;
	&:hover{
		background-color:$white;
		color:$black;
	}
}
.nav-tabs .nav-link.active, 
.nav-tabs .nav-item.show .nav-link {
    border-top: 3px solid $theme-primary-dark;
}


/* accordion */
.accordion {
    border: none;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
	margin:1rem 0;
	.accordion-item{	
		border: none;	
		.accordion-button{
			display:flex;
			justify-content: space-between;
			gap: 1.5rem;
			align-items: center;
			padding: 1rem 1.25rem;
			line-height: 1.3;
			@include ease;
			color:$black;
			background-color:$theme-grey-light;
			border-left: 4px solid $theme-primary;
			font-family:$theme-font-medium;
			border-radius:0.35rem;
			&:before{	
				content:"";			
				position:absolute;
				z-index:0;
				right:17px;
				border:1px solid $theme-tableborder;
				width:26px;
				aspect-ratio: 1/1;
				background-color:$white;
				border-radius:0.35rem;
			}
			&:after{
				z-index:1;
				background-size:contain;
			}
			&:hover{
				color:$theme-primary-dark;
				background-color:$theme-primary-light;
				border-left-color:$theme-primary-dark;
			}
			&[aria-expanded="true"]{
				background-color:$theme-primary-dark;
				border-left-color:$theme-primary-dark;
				color:$white;
			}
			&:not(.collapsed) {
				box-shadow: none;
			}
		}
		.accordion-collapse{
			border:none;
			.accordion-body{
				padding: 1.25rem;
				position:relative;
				border-radius:0.35rem;
				& p:last-child{
					margin-bottom:0;
				}
				&:before{
					content:"";
					display:block;
					position:absolute;
					top:1rem;
					left:0;
					bottom:1rem;
					width:4px;
					background-color:$theme-primary-dark;
					border-top-left-radius:0.35rem;
					border-bottom-left-radius:0.35rem;
				}
			}

		}
	}
	.accordion-item:first-of-type .accordion-button {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-radius:0.35rem;
	}
	.accordion-item:last-of-type .accordion-button.collapsed {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		border-radius:0.35rem;
	}
	.accordion-item:first-of-type {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	.accordion-item:last-of-type .accordion-collapse {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
}




/* header */
header{
	display:flex;
	flex-wrap:wrap;
	width:100%;
	min-height: 96px;
	background-color:$theme-primary;
	color:$white;
	.navbar {
		display:flex;
		flex-wrap:wrap;
		flex-grow: 1;
		padding: 15px 15px;
		position: relative;
		align-items: center;
		justify-content: space-between;
		gap:20px;
		.logo {
			display:flex;
			flex-wrap:wrap;
			align-items: center;;
			& > a{
				position:relative;
				width: 48px;
				margin: 0 30px 0 15px;
				display:flex;
				align-items: center;
				.signet{
					width: 48px;    
				}
			}
			.title{
				h1{
					margin:0;
					color:$white;
					font-size: 24px;
					letter-spacing: 0.1em;
					font-family: $theme-font-medium;
				}
				span{
					display: block;
					color:$white;
					font-size:16px;
					letter-spacing: 0.1em;
					margin-top: -5px;
				}
			}
		}
		.quicklinks{
			color:$white;
			display:flex;
			flex-wrap:wrap;
			gap:20px;
			a{
				color:$white;
				padding: 12px 24px;
				text-decoration: none;
				font-family:$theme-font-bold;
				border-radius: 0.35rem;
				background-color: rgba($theme-primary-dark,0.2);
				color: $white;
				@include ease;
				&:hover {
					background-color: $theme-primary-dark;
					border-color: $theme-primary-dark;
					color:$white;
				}
			}
		}
		.options {		
			display:flex;
			flex-wrap:wrap;
			align-items: center;
			gap:20px;
			padding-right: 15px;
			.button {
				cursor: pointer;
				display: inline-block;
				padding: 12px 24px;
				text-decoration: none;
				font-family:$theme-font-bold;
				display: flex;
				align-items: center;
				border-radius: 0.35rem;
				background-color: rgba($theme-primary-dark,0.2);
				color: $white;
				@include ease;
				&:hover {
					background-color: $theme-primary-dark;
					border-color: $theme-primary-dark;
					color:$white;
				}
			}
		}
	}
}












/* toolbar-lvl1 */
.toolbar-lvl1{
	background-color:$theme-grey-dark;
	margin-bottom:2rem;
	padding:0.5rem 0;
	.controls{
		display:flex;
		gap:80px;
		.filter{
			flex:1 1 100%;
			width:100%;
			form{
				height:100%;
				.row{
					height:100%;
					display:flex;
					align-items:center;
					gap: 15px 0;
					.col:not(.col-pagenav):not(.col-reset){
						display:flex;
						@include media-breakpoint-down(sm) {
							flex:1 1 100%;
							width:100%;
							.filter-group{
								flex-wrap:wrap;
							}
						}
					}
				}
			}
			.filter-group{
				display:flex;
				gap:5px;
				flex: 1 1 auto;
				.form-floating {
					flex: 1 1 auto;
					.select2{
						width:auto!important;
					}
				}
			}
		}
	}
	.form-control,
	.form-select{
		border-color:$theme-primary-dark;
	}
}

/* toolbar-lvl2 */
.toolbar-lvl2{
	margin-bottom:2rem;
	.controls{
		display:flex;
		gap:80px;
		background-color: $white;
		padding: 1rem;
		box-shadow: 0 4px 20px rgb(0 0 0 / 15%);
		border-top: 4px solid $theme-grey-dark;
		border-radius:0.35rem;
		.filter{
			flex:1 1 100%;
			width:100%;
			form{
				height:100%;
				.row{
					height:100%;
					display:flex;
					align-items:center;
					gap: 15px 0;
					.col{
						@include media-breakpoint-down(sm) {
							flex:1 1 100%;
							width:100%;
							.filter-group{
								flex-wrap:wrap;
							}
						}
					}
				}
			}
			.filter-group{
				display:flex;
				gap:5px;
				flex: 1 1 auto;
				.form-floating {
					flex: 1 1 auto;
					.select2{
						width:auto!important;
					}
				}
			}
			hr{
				margin:0;
			}
		}
	}
}








/* element */

.element{	
	margin-bottom: 2rem;	
	box-shadow: 0px 4px 20px rgba(0,0,0,.15);
	border-radius:0.35rem;
	overflow:hidden;
	.card-header{
		background-color:$theme-grey-dark;
		padding:1rem;
		display:flex;
		flex-wrap:wrap;
		align-items: center;
		.logo {
			display:flex;
			flex-wrap:wrap;
			align-items: center;;
			& > a{
				position:relative;
				width: 36px;
				margin: 0 20px 0 0;
				display:flex;
				align-items: center;
				.signet{
					width: 36px;    
				}
			}
			.title{
				h1{
					margin:0;
					color:$white;
					font-size: 21px;
					letter-spacing: 0.1em;
					font-family: $theme-font-medium;
				}
				span{
					display: block;
					color:$white;
					font-size:14px;
					letter-spacing: 0.1em;
					margin-top:-3px;
				}
			}
		}
		& + .card-headline{
			.headline{
				border-top:none;
			}
		}
	}
	.card-headline{
		.headline{
			margin:0;
			background-color:$white;
			padding:0.75rem 1rem;
			border-top: solid 4px $theme-grey-dark;
			position:relative; 
			z-index:1;
			display:flex;
			flex-wrap:wrap;
			justify-content: space-between;
			align-items: center;
			gap:0.75rem;
			.headline-title{				
				font-size: 1.25rem;
			}
			.headline-functions{
				display:flex;
				flex-wrap:wrap;
				align-items: center;
				gap:0.5rem;
			}
		}
	}
	.card {
		border: none;	
		border-top: solid 4px $theme-primary-dark;
		/* margin-bottom: 2rem; */
		border-radius:0;
		.inner{
			padding: 1rem;
		}
	}
	.card-headline + .card{
		/* border-top:none; */
		border-top: solid 1px $theme-tableborder;
	}
}

.login-container{
	width:100vw;
	height:100vh;
	display:flex;
	justify-content: center;
	align-items: center;
	.element{
		width:360px;
		margin: 15px;
		.form{
			padding-bottom:0;
			.login-tools{
				margin-top:1rem;
				font-size:14px;
				display:flex;
				justify-content: space-between;
				align-items: center;
				a{
					color:$theme-grey-dark;
					&:hover{
						color:$black;
						text-decoration: underline;
					}

				}
			}
			.form-footer{	
				display:flex;
				flex-direction: column;
				gap: 1rem;
				padding: 0;
				background-color:transparent;
				.btn-login{
					width:100%;
					justify-content: center;
				}
			}
		}
	}
}


.collapsing-element{  
	.card-headline{
		.headline{
			.headline-title{
				display:flex;
				align-items:center;
				padding-left:42px;
				@include ease;
				&:before{	
					content:"";			
					position:absolute;
					z-index:0;
					left:1rem;
					border:1px solid $theme-tableborder;
					width:26px;
					aspect-ratio: 1/1;
					background-color:$white;
					border-radius:0.35rem;
				}
				&:after{
					content:"";
					position:absolute;
					z-index:1;
					left:calc(1rem + 3px);
					top:17px;
					border:1px solid transparent;
					width:20px;
					aspect-ratio: 1/1;
					@include ease;
					transform: scaleY(-1);
					background-repeat: no-repeat;
					background-size: contain;
					background-position: 50% 50%;
					background-image: $link-button-icon;       
				}
				&.collapsed{
					&:after{
						transform: scaleY(1);
					}
				}
			}
		}
	}
	.card{
		margin-bottom:0;
	}
}


/* tablescroller */
.tablescroller {
	overflow:auto;
	/* cursor:grab; */
	&.tablescroller-dt{
		margin:0 -15px;
	}
}






/* table */
.table{
	width:100%;
	font-size: 14px;
	line-height:1.25;
	margin:10px 0 0 0;
	border-bottom:1px solid $theme-tableborder;
}
.table strong{
	font-family:$theme-tablefont-medium;
}
.table thead th{
	vertical-align:bottom;
	font-family:$theme-tablefont-medium;
	font-weight:normal;
	background-color:$white;
	color:$black;
	text-align:left;  
	/* padding-top:0.35rem;
	padding-bottom:0.35rem; */
	border-bottom:none;
	border-top:none;
}
.table tbody th{
	vertical-align:top;
	font-family:$theme-tablefont-medium;
	font-weight:normal;
	background-color: #f6f6f6;
	text-align:left; 
	font-size:14px; 
	border-left:1px dotted $theme-tableborder;
	border-right:1px dotted $theme-tableborder;
}
.table tbody th:nth-child(1){
	border-left:none;
}
.table tbody th:nth-last-child(1){
	border-right:none;
}
.table tbody tr {
	background-color:$white;
	.sum{
		background-color:rgba($black,0.05);
	}
}
.table.table-striped tbody tr:nth-of-type(2n+1) {
	background-color:rgba($black,0.035);
	.sum{
		background-color:rgba($black,0.1);
	}
}
.table th,
.table td{
	padding:10px;
	border-top: 1px solid $theme-tableborder;
	box-sizing: content-box;
}
.table td{
	vertical-align:middle;
	font-family:$theme-tablefont-regular;
	/* border-left:1px solid $theme-tableborder;
	border-right:1px solid $theme-tableborder; */
	hyphens: auto;
}
.table tr td:nth-child(1){
	border-left:none;
}
.table tr td:nth-last-child(1){
	border-right:none;
}

tr{
	&.bg-danger{
		color: $theme-danger!important;
		background-color:rgba($theme-danger,0.1)!important;
		&:nth-of-type(2n+1) > *{
			color: $theme-danger!important;
		}
		.sum{
			color:$white!important;
			background-color:rgba($theme-danger,1)!important;
		}
	}
	&.bg-warning{
		color: $theme-warning!important;
		background-color:rgba($theme-warning,0.1)!important;
		&:nth-of-type(2n+1) > *{
			color: $theme-warning!important;
		}
		.sum{
			color:$white!important;
			background-color:rgba($theme-warning,1)!important;
		}
	}
	&.bg-success{
		color: $theme-success!important;
		background-color:rgba($theme-success,0.1)!important;
		&:nth-of-type(2n+1) > *{
			color: $theme-success!important;
		}
		.sum{
			color:$white!important;
			background-color:rgba($theme-success,1)!important;
		}
	}
	&.bg-subsum{
		color: $black!important;
		background-color:rgba($black,0.15)!important;
		&:nth-of-type(2n+1) > *{
			color: $black!important;
		}
		.sum{
			color:$white!important;
			background-color:rgba($black,0.5)!important;
		}
	}
	&:hover {
		color: $black!important;
		background-color: rgba($theme-primary-light,0.5)!important;
		cursor:pointer;
		&:nth-of-type(2n+1) > *{
			color: $black!important;
		}
		.sum{
			color: $white!important;
			background-color: $theme-primary!important;
		}
		a{
			color:$black;
		}
	}
}

tbody{
	tr{
		&:hover {
			background-color: rgba($theme-primary-light,0.5)!important;
			td,
			th{
				background-color: rgba($theme-primary-light,0.5)!important;
			}
		}
	}
}





/* pagenav */
.col-pagenav{
	flex: 0 0 75px;
	display:flex;
	justify-content: center;    
	padding: 10px 0;
}
.pagenav{       
	margin:0 0 0 10px;
	color: $white;
}

.nav-icon {
	display: flex;
	align-items: center;
	height: 100%; 
}

/* reset filter */
.col-reset{
	flex: 0 0 75px;
	.reset{
		font-size:0;
		width:32px;					
		aspect-ratio: 1/1;
		border:none;
		top: -0.25rem;
		position: relative;
		filter: invert(89%) sepia(68%) saturate(2%) hue-rotate(250deg) brightness(107%) contrast(101%);		
		background-color:transparent;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size:contain;
		background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNDMgMjMyLjciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0MyAyMzIuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZD0iTTIwOC41LDQxLjdsLTEwLjgsMTAuOGwxMC44LDEwLjhjMiwyLDIsNS4xLDAsNy4yYy0xLjksMS45LTUsMi4xLTcuMiwwbC0xMC44LTEwLjhsLTEwLjgsMTAuOGMtMS45LDEuOS01LDIuMS03LjIsMA0KCWMtMi0yLTItNS4xLDAtNy4ybDEwLjgtMTAuOGwtMTAuOC0xMC44Yy0yLTItMi01LjEsMC03LjJzNS4xLTIsNy4yLDBsMTAuOCwxMC44bDEwLjgtMTAuOGMyLTIsNS4xLTIsNy4yLDANCglDMjEwLjYsMzYuNiwyMTAuNiwzOS42LDIwOC41LDQxLjdMMjA4LjUsNDEuN3ogTTIyNi45LDg4LjhjLTEzLjYsMTMuNi0zNC45LDE5LjQtNTUuOCwxMC44bC00Miw0MnY2OS42YzAsMi0xLDMuNi0zLjEsNC42DQoJbC0zMC43LDE1LjRjLTEuMywxLjMtMy4xLDAtNC42LDBjLTEuNS0xLTIuNi0zLjEtMi42LTQuNnYtODVMMi43LDU2Yy0xLjUtMS41LTItMy42LTEtNS42YzEtMiwyLjYtMy4xLDQuNi0zLjFoMTMzLjYNCgljMS0xMS4zLDYuMS0yMi41LDE0LjgtMzEuMmMxOC4yLTE5LjIsNTEuOC0yMC40LDcyLjIsMEMyNDYuOSwzNi4xLDI0Ni45LDY4LjgsMjI2LjksODguOEwyMjYuOSw4OC44eiBNMTYxLjQsOTQuNA0KCWMtMi42LTEuNS01LjEtMy42LTcuMi02LjFjLTguNy04LjctMTMuOC0yMC0xNC44LTMxLjJMMTguNSw1N2w3OC4zLDc4LjNjMSwxLDEuNSwyLDEuNSwzLjZsMCw3OC44bDIwLjUtMTAuMmwwLTY4LjENCgljMC0xLjUsMC41LTIuNiwxLjUtMy42TDE2MS40LDk0LjR6IE0yMTkuOCwyMy4zYy0xNi44LTE1LjctNDEuNi0xNS43LTU4LjQsMGMtMTUuOSwxNS45LTE1LjksNDIsMCw1Ny45YzE0LjYsMTQuNiw0MC40LDE2LjgsNTguNCwwDQoJQzIzNS42LDY1LjIsMjM1LjYsMzkuNiwyMTkuOCwyMy4zeiIvPg0KPC9zdmc+DQo=");
	}
}



/* object */
.object{
	display:flex;
	flex-wrap: wrap;
	gap:30px;
	.object-col{
		flex:1 1 calc(33.333% - 60px);
		&.object-image{
			flex:1 1 calc(15% - 60px);
			min-width:300px;
			@include media-breakpoint-down(lg) {
				flex:1 1 100%;
				width:100%;
			}
		}
		&.object-data{
			flex:1 1 calc(25% - 60px);
			min-width:300px;
			padding: 1rem 0;
			@include media-breakpoint-down(lg) {
				flex:1 1 100%;
				width:100%;
			}
		}
		&.object-data-wide{
			flex:1 1 calc(48% - 60px);
			min-width:300px;
			padding: 1rem 0;
			@include media-breakpoint-down(lg) {
				flex:1 1 100%;
				width:100%;
			}
		}
		.od-headline{
			font-family:$theme-font-medium;
			display:flex;
			justify-content: space-between;
			margin-bottom: 1.5rem;
			.navicon{
				margin-top: -0.25rem;
				color:rgba($black,0.2);
				&:hover{
					color:$theme-primary-dark;
				}
				span{
					margin:0;
					font-size:1.75rem;
				}
			}
		}
		&.object-links{
			min-width:300px;
			display:flex;
			gap:10px;
			flex-direction:column;
			@include media-breakpoint-down(lg) {
				flex:1 1 100%;
				width:100%;
			}
			.ol-item{
				width:100%;
				a{
					display:flex;
					width:100%;
					justify-content: space-between;
					gap: 1.5rem;
					align-items: center;
					padding:0.5rem 0.75rem;
					line-height: 1.3;
					@include ease;
					color:$black;
					background-color:$theme-grey-light;
					border-left: 4px solid $theme-primary;
					border-radius:0.35rem;
					&:hover{
						color:$theme-primary-dark;
						background-color:$theme-primary-light;
						border-left-color:$theme-primary-dark;
					}
					.ol-name{
						flex:1 1 42%;
						font-family:$theme-font-medium;
						hyphens:auto;
						position:relative;
						padding-left: 40px;    
						display: flex;
						align-items: center;
						&:before{	
							content:"";			
							position:absolute;
							z-index:0;
							left:0;
							border:1px solid $theme-tableborder;
							width:26px;
							aspect-ratio: 1/1;
							background-color:$white;
							border-radius:0.35rem;
						}
						&:after{
							content:"";
							position:absolute;
							z-index:1;
							left:3px;
							border:1px solid transparent;
							width:20px;
							aspect-ratio: 1/1;
							@include ease;
							transform: rotate(-90deg);
							background-repeat: no-repeat;
							background-position: 50% 50%;
							background-size:contain;
							background-image: $link-button-icon;
						}
					}
					.ol-status{
						flex:1 1 33%;		
					}
					.ol-check{
						flex:1 1 25%;						
					}
				}
			}
		}
	}
}


/* form */
.form{
	padding:1rem 0 3rem 0;
	@include ease;
	.form-footer{
		margin-top:2rem;
		padding:1rem;
		border-radius:0.35rem;
		background-color:$theme-grey-light;
		@include ease;
		.container{			
			display:flex;
			flex-wrap:wrap;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
			padding: 0;
			max-width:calc(1042px - 2rem);
		}
		&.fixed{			
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1000;
			margin: auto;
			border-radius:0;
			background-color:$white;
			/* box-shadow: 0 4px 20px rgb(0 0 0 / 15%); */
			border-top:4px solid $theme-grey-dark;
		}
	}
	.form-toolbar{
		margin-top:0;
		padding:1rem;
		border-radius:0.35rem;
		background-color:$theme-grey-light;
		@include ease;
		.container{			
			display:flex;
			flex-wrap:wrap;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
			padding: 0;
			max-width:calc(1042px - 2rem);
		}
	}
	.subheading{
		margin-top: 3rem;
		margin-bottom: 1.5rem;
	}
}


/* checklist */
.checklist{
	margin-top:2rem;
}
.accordion-checklist{
	.accordion-item{
		.accordion-button{		
			border:none;	
			&:hover,
			&[aria-expanded="true"]{
				color:$black;
				background-color:$theme-grey-light;
			}
		}
		.accordion-collapse{
			.accordion-body{
				margin-top:10px;
				border:1px solid $theme-tableborder;
				&:before{
					content:normal;
				}
			}
		}
	}
}
.ah-title{
	display:flex;
	flex-wrap:wrap;
	gap:3rem;
}
.tab-content > .tab-pane {
    padding:1rem 0;
}
.map-container {
    width: 100%;
    float: left;
    position: relative;
    padding: 0 0 40px 0;
	.span12 {
		position: absolute;
		margin: 0;
	}
	.map-container-inner {
		position: relative;
		left: 0;
		top: 0;
		width: 100%;
		height: 0;
		overflow: hidden;
	}
}
.hasPlaceholder {
    color: #999 !important
}
#ajaxoverlay {
    opacity: .4;
    position: fixed;
    top: 0;
    left: 0;
    background-color: black;
    width: 100%;
    z-index: 9999;
    text-align: center;
	img {
		position: absolute;
		display: none;
	}
}


/* table fixhead */
.tableFixHead {
	overflow-y: auto; 
	height: 70vh; 
	.text-right {
		text-align: right;
	}
	.text-left {
		text-align: left;
	}
	.border {
		border: 1px solid $theme-tableborder!important;
	}
	.border-none {
		border: none;
	}
	.border-left {
		border-left: 1px solid $theme-tableborder!important;
	}
	.border-right {
		border-right: 1px solid $theme-tableborder!important;
	}
	.border-bottom {
		border-bottom: 1px solid $theme-tableborder!important;
	}
	.border-top {
		border-top: 1px solid $theme-tableborder!important;
	}	
	.input-preis {
		padding:7px;
		input {
			max-width: 13em;
			text-align: end;
			font-size: 14px;
		}
	}
	.input-value {
		padding: 0;
		input {
			margin: 0;
			text-align: end;
			font-size: 14px;
		}
	}
	.text-warnung {
		color: $theme-danger;
		font-weight: normal;
		font-family:$theme-font-bold;
	}
	table {
		border-collapse: collapse; 
		width: 100%;
		margin:0;
		&.table {
			border: 1px solid $theme-tableborder!important;
		}
		thead{
			tr {
				position: sticky; 
				top: -1px; 
				th{
					position:relative;
					background: $theme-grey-medium2;
					text-align:left;
				}
			}
			.border-left{
				&:before{
					content:"";
					position:absolute;
					width:1px;
					top:0;
					bottom:0;
					left:-1px;
					background-color:$theme-tableborder;
				}
			}
			.border-right{
				&:after{
					content:"";
					position:absolute;
					width:1px;
					top:0;
					bottom:0;
					right:-1px;
					background-color:$theme-tableborder;
				}
			}
		}
		tbody{
			th {
				border-left:none;
				border-right:none;
				text-align:left;
			}    
		}
		tfoot{
			th {
				background: $theme-grey-medium2;
				text-align:left;
			}  
		}
	}
}


/* log-list */
.log-list{
	display:flex;
	flex-wrap:wrap;
	gap:1.25rem;
	.item{
		background-color:$theme-grey-light;
		border-radius:0.35rem;
		padding:1.25rem;	
		flex:0 0 100%;
		display: flex;
    	flex-direction: column;
		@include media-breakpoint-up(lg) {
			flex:0 1 calc(50% - 0.625rem);
		}
		.img {
			margin:0 0 2rem 0;
			cursor: pointer;
			aspect-ratio: 16/9;
			border-radius:0.35rem;
			overflow:hidden;
			img{
				object-fit: cover;
				object-position: 50% 0%;
				width:100%;
				height:100%;
			}
		}
		dl{
			word-break: break-all;	
			line-break: anywhere;
			dt{
				margin-top:0.5rem;
				&:first-of-type{
					margin-top:0;
				}
			}
			dd{
				margin:0;
				&:empty{
					display:none;
				}				
			}
		}
		.buttons{
			margin-top:auto;
			padding-top:1rem;
			border-top:1px solid $theme-tableborder;
		}
	}
}