@use "../../mixins" as m;
@use "../../variables" as v;

:root {
    --mm-lineheight: 22px;
}

.mm-hidden {
    display: none !important;
}

.mm-wrapper {
    overflow-x: hidden;
    position: relative;
}

.mm-menu,
.mm-menu *,
.mm-menu *:before,
.mm-menu *:after {
    transition-property: none;
    transition-duration: v.$transDr;
    transition-timing-function: v.$transFn;
}

@import "blocker", "button", "divider", "listitem", "listview", "menu", "navbar",
    "panel", "panels", "toggle", "vertical";
