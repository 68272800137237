/* -------------------------------- */
/* BGW-Bielefeld Verkehrssicherheit */
/* -------------------------------- */


// Fonts

/* jost */
/* @font-face {
  font-family: "Jost 100";
  font-style: normal;
  font-weight: 100;
  src: local(''),
       url('./webfont/jost/jost-v14-latin-100.woff2') format('woff2'), 
       url('./webfont/jost/jost-v14-latin-100.woff') format('woff'); 
} */
/* @font-face {
  font-family: "Jost 200";
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('./webfont/jost/jost-v14-latin-200.woff2') format('woff2'), 
       url('./webfont/jost/jost-v14-latin-200.woff') format('woff'); 
} */
@font-face {
  font-family: "Jost 300";
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('./webfont/jost/jost-v14-latin-300.woff2') format('woff2'), 
       url('./webfont/jost/jost-v14-latin-300.woff') format('woff'); 
}
@font-face {
  font-family: "Jost 400";
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('./webfont/jost/jost-v14-latin-regular.woff2') format('woff2'), 
       url('./webfont/jost/jost-v14-latin-regular.woff') format('woff'); 
}
@font-face {
  font-family: "Jost 500";
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('./webfont/jost/jost-v14-latin-500.woff2') format('woff2'), 
       url('./webfont/jost/jost-v14-latin-500.woff') format('woff'); 
}
@font-face {
  font-family: "Jost 600";
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('./webfont/jost/jost-v14-latin-600.woff2') format('woff2'), 
       url('./webfont/jost/jost-v14-latin-600.woff') format('woff'); 
}
@font-face {
  font-family: "Jost 700";
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('./webfont/jost/jost-v14-latin-700.woff2') format('woff2'), 
       url('./webfont/jost/jost-v14-latin-700.woff') format('woff'); 
}
/* @font-face {
  font-family: "Jost 800";
  font-style: normal;
  font-weight: 800;
  src: local(''),
       url('./webfont/jost/jost-v14-latin-800.woff2') format('woff2'), 
       url('./webfont/jost/jost-v14-latin-800.woff') format('woff'); 
} */
/* @font-face {
  font-family: "Jost 900";
  font-style: normal;
  font-weight: 900;
  src: local(''),
       url('./webfont/jost/jost-v14-latin-900.woff2') format('woff2'), 
       url('./webfont/jost/jost-v14-latin-900.woff') format('woff'); 
} */



$theme-font-light:"Jost 300";
$theme-font-regular:"Jost 400";
$theme-font-mediumlight:"Jost 500";
$theme-font-medium:"Jost 600";
$theme-font-bold:"Jost 700";

$theme-tablefont-light:"Jost 300";
$theme-tablefont-regular:"Jost 400";
$theme-tablefont-mediumlight:"Jost 500";
$theme-tablefont-medium:"Jost 600";
$theme-tablefont-bold:"Jost 700";


@import "./icons/style.css";
$theme-icon: 'icomoon';

 

// Custom Colors

$theme-primary: rgb(153,168,0);
$theme-primary-dark: rgb(33,78,40);
$theme-primary-light: rgb(211,216,161);
$theme-primary-verylight: rgb(242, 243, 226);
$theme-orange: rgb(233,94,77);
$theme-red: rgb(156,23,54);
$theme-blue: rgb(106,160,172);
$theme-grey-light: rgb(242,240,239);
$theme-grey-medium: rgb(221,221,221);
$theme-grey-medium2: rgb(230, 230, 230);
$theme-grey-dark: rgb(108,108,108);
$theme-tableborder: rgb(200,200,200);

$theme-success: rgb(82,183,54);/* 33,78,40 */
$theme-warning: rgb(255,160,0);/* 233,94,77 */
$theme-danger: rgb(227,40,17);/* 156,23,54 */
$theme-info: rgb(106,160,172);
$theme-accent: rgb(223,227,176);
$theme-done: $theme-grey-dark;



// Easing
@mixin ease {
  transition: all 0.15s ease-in-out;
}


$link-button-icon:         url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
$link-button-active-icon:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");


/* >>>>>>>>>>>>>>>>>>>> BOOTSTRAP */


// required
@import "../node_modules/bootstrap/scss/functions";

//  START Variables override (from _variables.scss)

/* IMPORTANT: make sure to have all bootstrap-variables you want to use in the overrides here inside, before bootstrap.scss is imported */

// Color system

$black: #000000;
$white: #ffffff;
$primary: $theme-primary;
$secondary: $theme-grey-dark;
$success:$theme-success;
$info:$theme-info;
$warning:$theme-warning;
$danger:$theme-danger;
$dark:$theme-primary-dark;

/* $secondary-darker:mix($black, $secondary, 50%); */

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $black;

// Links
//
// Style anchor elements.

$link-color: $theme-primary;
$link-decoration: none;
$link-hover-decoration: none;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1.5rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px, /* additional */
  xxxxl: 1920px, /* additional */
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px,
  xxxl: 1480px, /* additional */
  xxxxl: 1640px, /* additional */
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           30px;

// Components
//
// Define common padding and border radius sizes and more.

$border-width: 					1px;
$border-color: 					#ebedef;

$border-radius:               	0.35rem;
$border-radius-lg:            	0.35rem;
$border-radius-sm:            	0.35rem;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: $theme-font-regular, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-base: 1rem; // Assumes the browser default, typically `16px`

$font-weight-normal: 400; /* = regular */
$font-weight-semi: 600; /* = semibold */
$font-weight-bold: 700; /* = bold */

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: 40px;
$h2-font-size: 32px; 
$h3-font-size: 24px;
$h4-font-size: 20px;
$h5-font-size: 14px;
$h6-font-size: 12px;

$headings-margin-bottom:	1.5rem;
$headings-font-family: 		$theme-font-regular;
$headings-font-weight: 		$font-weight-normal;
$headings-line-height: 		1.2;
$headings-color: 			    $black;

$hr-border-color:         $theme-tableborder; 
$hr-border-width:         1px;
$hr-opacity:              1;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .25rem;
$table-cell-padding-sm:       .1rem;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         0.667rem;
$input-btn-padding-x:         1.0rem;

$input-btn-padding-y-sm:      0.25rem;
$input-btn-padding-x-sm:      1.0rem;

$input-btn-focus-width:       0;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm;

$input-btn-font-size:         16px;
$btn-font-weight:             $font-weight-semi;

// Forms

$label-margin-bottom:                   .5rem;

$input-font-family:                     $theme-font-regular;
$input-font-size:                       $input-btn-font-size;
$input-font-weight:                     $font-weight-normal;

$input-bg:                              $white;

$input-color:                           $theme-primary-dark;
$input-border-color:                    $theme-tableborder;

$input-focus-bg:                        $white;
$input-focus-border-color:              $theme-primary-dark;
$input-focus-color:                     $black;

$input-placeholder-color:               #6c757d;

$form-check-input-gutter:               1.50rem;
$custom-control-spacer-x:               2rem;
/* $form-check-input-margin-y:             0.5rem; */
/* $form-check-input-margin-x:             0.5rem; */

$form-check-inline-margin-x:            0.75rem;
$form-check-inline-input-margin-x:      0.75rem;

$custom-control-indicator-size:         1.25rem;
$custom-control-indicator-border-color: $theme-primary-light;

$custom-select-font-size:               16px;

$custom-checkbox-indicator-border-radius:       0;

$form-select-indicator-color:           $theme-primary-dark;

// Navs
$nav-tabs-border-color:             $theme-tableborder;
$nav-tabs-link-active-bg:           $body-bg;
$nav-tabs-link-hover-border-color:  $theme-tableborder $theme-tableborder transparent;
$nav-tabs-link-active-border-color: $theme-tableborder $theme-tableborder $nav-tabs-link-active-bg;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-border-radius:            10px;

// Pagination

$pagination-padding-y:              0.31rem;
$pagination-padding-x:              0.5rem;

$pagination-bg:                     transparent;
$pagination-border-color:           $theme-primary;

$pagination-hover-bg:               transparent;
$pagination-hover-border-color:     $theme-primary;

// Breadcrumbs

$breadcrumb-font-size:              16px;

$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           20px;

$breadcrumb-margin-bottom:          0;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $theme-primary;
$breadcrumb-active-color:           $theme-primary;
$breadcrumb-divider:                quote("/");

$breadcrumb-border-radius:          0;

// Accordion


$accordion-icon-width:                    20px;
$accordion-icon-transform:                scaleY(-1);
$accordion-icon-color:                    $black;
$accordion-icon-active-color:             $black;
$accordion-icon-transition:               transform .3s ease-in-out;





// END Variables override




/* @import "../node_modules/bootstrap/scss/bootstrap.scss"; */

// required
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

//optional
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";

@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/placeholders";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/utilities/api";


/* BOOTSTRAP <<<<<<<<<<<<<<<<<<<< */




// modules
@import "./modules.scss";

// global
@import "./global.scss";

