$mm_module: ".mm-navbar";

:root {
    --mm-navbar-size: 50px;
}

#{$mm_module} {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 2;
    min-height: var(--mm-navbar-size);
    padding-top: env(safe-area-inset-top);
    color: var(--mm-color-text-dimmed);
    text-align: center;
    opacity: 1;
    background: var(--mm-color-background);
    border-bottom: 1px solid var(--mm-color-border);
    transition-property: opacity;


    > * {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
}

#{$mm_module}__btn {
    flex-grow: 0;
}

#{$mm_module}__title {
    flex: 1 1 50%;
    display: flex;
    padding: 0 v.$panelPadding;
    overflow: hidden;

    &[href="#"] {
        pointer-events: none;
    }

    > span {
        @include m.ellipsis;
    }

    // Center the content.
    .mm-btn.mm-hidden + &:not(:last-child) {
        padding-inline-start: v.$btnSize + 10; // left, right for RTL
        padding-inline-end: 10px; // right, left for RTL
    }

    .mm-btn:not(.mm-hidden) + &:last-child {
        padding-inline-start: 10px; // left, right for RTL
        padding-inline-end: v.$btnSize + 10; // right, left for RTL
    }
    // /Center
}
