@import "../node_modules/mmenu-js/src/mmenu.scss";
@import "../node_modules/mburger-webcomponent/src/mburger/index.scss";
//@import "../node_modules/datatables.net-dt/css/jquery.dataTables.css";
@import "../node_modules/datatables.net-bs5/css/dataTables.bootstrap5.css";
@import "../node_modules/datatables.net-buttons-bs5/css/buttons.bootstrap5.css";
@import "../node_modules/datatables.net-fixedheader-bs5/css/fixedHeader.bootstrap5.css";

@import "./modules/mmenu-js/mmenu-js.scss";
@import "./modules/datatables/datatables.scss";
@import "./modules/flatpickr/flatpickr.scss";
@import "./modules/select2/select2.scss";
@import "./modules/dropzone/dropzone.scss";
@import "./modules/vanilla-lazyload/vanilla-lazyload.scss";
@import "./modules/swiper/swiper.scss";