@import '/node_modules/select2/src/scss/core.scss';
@import "/node_modules/select2-bootstrap-5-theme/src/select2-bootstrap-5-theme";


.form-floating {
	.select2{
		width:auto!important;
	}
}
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered {
    margin-top: 14px;
    gap: 5px;
}
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    border: none;
    margin-bottom: 0;
    margin-right: 0;
    padding: 2px 7px;
    line-height: 1;
    background-color: #f2f0ef;
}
.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
    width: 0.50rem;
    height: 0.50rem;
    margin-right: 0.35rem;
}
.select2-container--bootstrap-5 .select2-selection--multiple .select2-search {
    display:none;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected, 
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected="true"]:not(.select2-results__option--highlighted) {
    color: #ffffff!important;
    background-color:#99A800!important;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--highlighted {
    color:#000000;
    background-color: #E9EBD0;
}
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role="group"] .select2-results__group {
    display: block;
}