@import "../node_modules/dropzone/dist/dropzone.css";

/* dropzone */
.dropzone{
	margin-bottom: 2rem;
	min-height: 100px;
    border: 2px dashed $theme-tableborder;    
    border-radius: 0.35rem;
	background-color:$theme-grey-light;
}
.dropzone .dz-preview {
    margin: 10px;
}
.dropzone .dz-preview .dz-image{
	border-radius: 0;
}
.dropzone.is-invalid {
    border-color: #e84e0f;
}
.dropzone .dz-message {
    margin: 1em 0;    
    display: flex;
    justify-content: center;
}
.dropzone .dz-message .dz-button {
    font-family:$theme-font-medium;
    color:$theme-grey-dark;
    position:relative;
    display:flex;
    flex-direction:column;
    align-items: center;
}
.dropzone .dz-message .dz-button:before {
    font-family:$theme-icon;
    content:"\ea45";
    font-size:2em;
}

.dropzone .dz-preview .dz-remove {
    padding: 10px 5px;
}