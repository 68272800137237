$mm_module: ".mm-panel";

#{$mm_module} {
    --mm-panel-child-offset: 100%;
    --mm-panel-parent-offset: -30%;

    [dir="rtl"] & {
        --mm-panel-child-offset: -100%;
        --mm-panel-parent-offset: 30%;
    }

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;

    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;

    color: var(--mm-color-text);
    border-color: var(--mm-color-border);
    background: var(--mm-color-background);

    transform: translate3d(var(--mm-panel-child-offset), 0, 0);
    transition-property: transform,inset-inline-start;

    //	Because padding-bottom is ignored when a DIV is scrollable
    &:after {
        content: "";
        display: block;
        height: var(--mm-listitem-size);
    }

    &:focus {
        outline: none;
    }

    &--opened {
        z-index: 2;
        transform: translate3d(0%, 0, 0);
        transition-delay: 0s;
    }

    &--parent {
        transform: translate3d(var(--mm-panel-parent-offset), 0, 0);
        z-index: 0;
    }

    &--highest {
        z-index: 3;
    }

    &--noanimation {
        .mm-menu--opened & {
            transition: none !important;
            transition-duration: 0s !important;
        }
    }
}

#{$mm_module}__content {
    padding: v.$panelPadding;
}
