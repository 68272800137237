:root {
    --mm-size: 80%;
    --mm-min-size: 240px;
    --mm-max-size: 280px;
}


#{$module}-white {
    --mm-color-background: #6c6c6c;
    --mm-color-border: rgb(255 255 255 / 0.15);
    --mm-color-icon: rgb(255 255 255 / 0.3);
    --mm-color-text: rgb(255 255 255 / 0.8);
    --mm-color-text-dimmed: rgb(255 255 255 / 0.9);
    --mm-color-background-highlight: rgb(255 255 255 / 0.06);
    --mm-color-background-emphasis: rgb(255 255 255 / 0.03);
    --mm-color-focusring: #ffffff;

    &-contrast {
        --mm-color-background: #000000;
        --mm-color-border: rgb(255 255 255 / 0.5);
        --mm-color-icon: rgb(255 255 255 / 0.5);
        --mm-color-text: #000;
        --mm-color-text-dimmed: rgb(255 255 255 / 0.7);
        --mm-color-background-highlight: rgb(255 255 255 / 0.07);
        --mm-color-background-emphasis: rgb(255 255 255 / 0.035);
        --mm-color-focusring: #ffffff;
    }
}


#my-menu:not( .mm-menu ) {
    display: none;
}
.mm-wrapper--position-left .mm-slideout, .mm-wrapper--position-right .mm-slideout {
    transform: none;
}
.mm-listitem__btn {
    display: block!important;
}
.mm-navbar {
    height: 125px;
	font-size:20px;
}
.mm-wrapper--opened .mm-wrapper__blocker {
    opacity: 0;
}
.mm-listview{
	margin:0 20px;
}
.mm-listitem__text{
	padding-left: 0;
    padding-right: 0;
}
.mm-menu--offcanvas {
    position: absolute;
}


.mm-menu {font-size:16px;}

.mm-listitem:after {left: 0!important; border-bottom-width: 1px!important;}
.mm-listitem{font-family:$theme-font-bold;}
.mm-listitem__text {white-space: normal!important;}
.mm-navbar{border-bottom-width: 0!important;}
#mm-0 .mm-navbar{display:none;}
.mm-navbar__title {justify-content: flex-start!important; font-family:$theme-font-bold!important;}

.mm-btn:before, 
.mm-btn:after {border-width: 1px!important; width: 10px!important; height: 10px!important;}

